Sk.sliders = {

  init: function() {
    this.bindEvents();
  },

  bindEvents: function() {
    var $sliders = $('.slider');

    if (!$sliders.length) {
      return;
    }

    $sliders.each(function(i, elem) {
      var flkty = $(elem).data('flickity');

      // Make sure flickity is already initialized
      if(flkty.element === undefined) {
        setTimeout(function() {
          Sk.sliders.bindEvents();
        }, 50);
        return;
      }

      // Initalize and listen for slide change
      flkty.on('change', function(index) {
        console.log('change');
        Sk.sliders.autoPlayVideo(flkty);
        Sk.sliders.autoCountUp(flkty);
      });
      Sk.sliders.autoPlayVideo(flkty);
    });
  },

  autoPlayVideo: function(flkty) {
    var $inactiveVideos = $(flkty.element).find('video');
    var $activeVideos = $(flkty.selectedElements).find('video');

    if($inactiveVideos.length) {
      $inactiveVideos.each(function() {
        this.pause();
      });
    }

    if($activeVideos.length) {
      $activeVideos.each(function() {
        this.play();
      });
    }
  },

  autoCountUp: function(flkty) {
    var $activeCounters = $(flkty.selectedElements).find('.sk-countup');

    if(!$activeCounters.length) {
      return;
    }
    
    $activeCounters.each(function(i, elem) {
      var numAnim = $(elem).data('countup');
      if (numAnim) {
        numAnim.reset();
      }
    });
  }

};


$(function() {
  Sk.sliders.init();
})

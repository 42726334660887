Sk.maps = {

  init: function(elem, options) {

    var mapOptions = {
      zoom: 8,
      disableDefaultUI: true,
      zoomControl: true,
      type: 'roadmap',
      styles: this.getStyles()
    };

    $.extend(mapOptions, options);

    var map = new google.maps.Map(elem, mapOptions);
    var bounds = new google.maps.LatLngBounds();

    options.markers.forEach(function(value, index) {
      bounds.extend(value);

      new google.maps.Marker({
        position: value,
        map: map,
        icon: {
          url: '/assets/img/marker.svg',
          size: new google.maps.Size(11,11)
        }
      });
    });

    if (mapOptions.markers.length > 1) {
      // auto zoom and center
      map.fitBounds(bounds);
      map.panToBounds(bounds);
    }
  },

  getStyles: function() {
    return [
      {
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#c0c0c0"
          },
          {
            "visibility": "on"
          },
          {
            "weight": 2.0
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#c0c0c0"
          },
          {
            "visibility": "on"
          },
          {
            "weight": 1.0
          }
        ]
      },
      {
        "featureType": "landscape",
        "stylers": [
          {
            "color": "#f4f4f4"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      }
    ]
  }
};

Sk.filters = {

  init: function() {

    // Toggle filter panel visibility
    $('#content').on('click', '[data-toggle="filters"]', Sk.filters.toggle);

    // Delete filter
    $('#content').on('click', '[data-dismiss="filter"]', Sk.filters.dismiss);

    // Submit filters
    $('#content').on('submit', '[data-js="filters-form"]', Sk.filters.handleSubmit);
    $('#content').on('change', '[data-js="filters-form"] select', Sk.filters.handleChange);

    // Pagination links
    $('#content').on('click', '[data-js="pager"] a', Sk.filters.handlePager);

    // Load more link
    $('#content').on('click', 'a[data-js="loader"]', Sk.filters.handleLoader);
  },

  updateGrid: function() {
    // force masonry layout update as images load
    var $grid = $('.grid[data-masonry]');

    if($grid.length) {
      $grid.masonry();
      $grid.imagesLoaded().progress(function(){
        $grid.masonry('layout');
      });
    }
  },

  updateUrl: function(url) {
    if(window.history.pushState) {
      window.history.pushState({}, '', url);
    }
  },

  handleLoader: function(e) {
    e.preventDefault();

    var $link = $(this);
    var url = $link.attr('href');
    var target = $link.attr('data-target');
    var $container = $(target);
    var $inner = $container.find('#search-contents');
    var $filters = $('.block--filters');

    $link.text('Loading...').addClass('disabled').on('click', function() { return false; });

    if($container.length) {
      console.log('loading...')
      $.get(url, function(data) {
        $link.remove();
        $filters.remove();
        $data = $(data).find(target);
        // filters
        $container.prepend($data.find('.block--filters'));
        // content
        var $content = $data.find('#search-contents').contents();
        $inner.append($content);
        // update masonry with appended data
        if($inner.is('[data-masonry]')) {
          $inner.masonry('appended', $content);
        }
        // load more button
        $container.next('.block--action');
        $data.find('.block--action').insertAfter($container);
      });
    } else {
      window.location = url;
    }
  },

  handlePager: function(e) {
    e.preventDefault();

    console.log('pager...');

    var $link = $(this);
    var url = $link.attr('href');
    var target = $link.parents('[data-target]').attr('data-target');
    var $container = $(target);
    var $inner = $container.find('#search-contents');

    if($container.length) {
      console.log('loading...')
      $.get(url, function(data) {
        $container.replaceWith($(data).find(target));
        Sk.filters.updateUrl(url);
        // initialize masonry
        Sk.filters.updateGrid();
      });
    } else {
      window.location = url;
    }
  },

  handleChange: function(e) {
    $(this).parents('[data-js="filters-form"]').trigger('submit');
  },

  handleSubmit: function(e) {
    e.preventDefault();

    var $form = $(this);
    var target = $form.attr('data-target');
    var $container = $(target);
    var $inner = $container.find('#search-contents');
    var action = $form.attr('action');
    var params = $form.serialize();
    var url = $form.attr('action') + '?' + params;
    var $tab = $('#search-contents').parents('.tabs__pane');

    if($container.length) {
      console.log('loading...', params);
      $.get(url, function(data) {
        $container.replaceWith($(data).find(target));
        Sk.filters.updateUrl(url);
        // initialize masonry
        Sk.filters.updateGrid();
      });
    } else {
      window.location = url;
    }
  },

  toggle: function(e) {
    $toggle = $(this);
    $target = $($toggle.attr('data-target'));

    if(!$target.length) {
      return;
    }

    $toggle.toggleClass('is-active').blur();
    $target.find('input').first().focus();
  },

  dismiss: function(e) {
    e.preventDefault();

    var $filter = $(this);
    var name = $filter.attr('data-filter');
    var $input = $('[data-js="filters-form"]').find('input[name="'+name+'"]');
    var $select = $('[data-js="filters-form"]').find('select[name="'+name+'"]');

    if($input.length) {
      $input[0].value = '';
    }

    if($select.length) {
      $select[0].selectedIndex = 0;
    }

    $(this).remove();

    $('[data-js="filters-form"]').trigger('submit');
  }

};


$(function() {
  Sk.filters.init();
})

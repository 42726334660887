var Sk = window.Sk || {};

Sk.common = {

  init: function() {
    this.navToggle();
    this.navSubnav();
    this.fixedHeader();
    this.searchToggle();
    this.expandBlock();
    this.visibilityToggle();
    this.visibilityFilter();
    this.eventListeners();

    $('html').removeClass('no-js').addClass('js');

    // object-fit image polyfill
    objectFitImages();

    // force masonry layout update as images load
    var $grid = $('.grid[data-masonry]');

    if($grid.length) {
      $grid.imagesLoaded().progress(function(){
        $grid.masonry('layout');
      });
    }

    // initialize modals
    $('[data-js="modal"]').modaal({
      type: 'ajax',
      custom_class: 'modaal-fullscreen modal-panel',
      animation: 'none',
      overlay_opacity: 0.7,
      loading_content: '<div class="sk-loader"><span class="sr-only">Loading</span></div>'
    });
  },

  visibilityToggle: function() {

    $('[data-toggle="visibility"]').on('click', function(e) {
      e.preventDefault();

      var $btn = $(this);
      var $target = $($btn.attr('data-target'));
      var state = $target.find('.is-visually-hidden').length ? 'hidden' : 'visible';
      var originalText = 'Show more';

      if($btn.data('originalText')) {
        originalText = $btn.data('originalText');
      } else {
        $btn.data('originalText', $btn.text());
        originalText = $btn.data('originalText');
      }

      if(!$target.length) {
        return;
      }

      if(state === 'visible') {
        $target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
        $btn.text(originalText).blur();
      } else {
        $target.find('.is-visually-hidden').removeClass('is-visually-hidden').addClass('is-visually-shown');
        $btn.text('Show less').blur();
      }
    });
  },

  visibilityFilter: function() {

    $('[data-toggle="filter"]').on('change', function(e) {
      e.preventDefault();

      var $select = $(this);
      var $target = $($select.attr('data-target'));
      var val = $select.val();

      if(!$target.length) {
        return;
      }

      if(!val) {
        $target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
        $select.blur();
        return;
      }

      $target.find('.is-visually-shown').removeClass('is-visually-shown').addClass('is-visually-hidden');
      $target.find('[data-id="'+val+'"]').removeClass('is-visually-hidden').addClass('is-visually-shown');
      $select.blur();
    });
  },

  searchToggle: function() {
    function escapeKey(e) {
      if (e.keyCode === 27) {
        $('.header__search-toggle').trigger('click');
      }
    }

    $('.header__search-toggle').on('click', function(e) {
      $('html').toggleClass('is-search-open');

      if(!$('html').hasClass('is-search-open')) {
        $('#site-search').attr('aria-expanded', 'false');
        $('.nav-backdrop').remove();
        $('#quicksearch')[0].blur();
        $(document).off('keyup', escapeKey);
      } else {
        $(document.createElement('div'))
          .addClass('nav-backdrop fade-in')
          .appendTo($('body'))
          .on('click', function(){
            $('.header__search-toggle').trigger('click');
        });
        $('#site-search').attr('aria-expanded', 'true');
        $('#quicksearch')[0].focus();
        $(document).on('keyup', escapeKey);
      }
    });

    $('.header__search .close').on('click', function(e) {
      $('.header__search-toggle').trigger('click');
    });
  },

  navToggle: function() {
    $('.header__nav-toggle').on('click', function(e) {
      var $toggle = $(this);
      var $menu = $('.header__nav');

      $menu.toggleClass('is-nav-active');
      $('html').toggleClass('is-nav-open');

      if(!$('html').hasClass('is-nav-open')) {
        $('.nav-backdrop').remove();
      } else {
        $(document.createElement('div'))
          .addClass('nav-backdrop fade-in')
          .appendTo($('body'))
          .on('click', function(){
            $toggle.trigger('click');
        });
      }

      $('html').removeClass('is-dropdown-open');

      // reset subnav after closing transition
      var timeout = setTimeout(function() {
        $('.header__nav-main li').removeClass('is-dropdown-active');
      }, 500);
    });

    $('.header__nav .close').on('click', function(e) {
      $('.header__nav-toggle').trigger('click');
    });

  },

  navSubnav: function() {
    $('.header__nav-main > li, .header__nav-language > li')
    .on('mouseenter click', function(e) {
      if(e.type == 'mouseenter' && Sk.common.isMobile()) {
        return;
      }

      // toggle subnav state
      var $node = $(this);

      if($node.find('.header__nav-dropdown').length) {
        // toggle dropdown on click but allow desktop click through
        if(e.type == 'click' && $(e.target).is('a') && $(e.target).parent().is($node) && Sk.common.isMobile()) {
          e.preventDefault();
          $('html').toggleClass('is-dropdown-open');
          $node.toggleClass('is-dropdown-active');
        }
      }

      if(e.type == 'mouseenter') {
        $('html').toggleClass('is-dropdown-open');
        $node.toggleClass('is-dropdown-active');
      }
    })
    .on('mouseleave', function(e) {
      if(Sk.common.isMobile()) {
        return;
      }

      // close subnav dropdown
      $('html').removeClass('is-dropdown-open');
      $(this).removeClass('is-dropdown-active');
    });

    $('.header__nav .back').on('click', function(e) {
      // subnav close button callback
      $('html').removeClass('is-dropdown-open');
      $('.is-dropdown-active').removeClass('is-dropdown-active');
    });
  },

  navState: function() {

    // Only reset on desktop
    if(Sk.common.isMobile()) {
      return;
    }

    $('html').removeClass('is-nav-open');
    $('.nav-backdrop').remove();
    $('html').removeClass('is-dropdown-open');
    $('.header__nav').toggleClass('is-nav-active');
    $('.is-dropdown-active').toggleClass('is-dropdown-active');
  },

  fixedHeader: function() {

    // Header is only fixed on mobile
    if(!Sk.common.isMobile()) {
      $('html').removeClass('is-nav-fixed');
      return;
    }

    var scrollPos = window.scrollY;
    var threshold = $('.site-header').height();

    if($('.hero').length) {
      threshold = $('.hero').height();
    }

    $('html').toggleClass('is-nav-fixed', (scrollPos >= threshold));
  },

  expandBlock: function(){

    $('.block.block--expand').each(function(){

      const $expandBlock = $(this);
      const $expandButton = $expandBlock.find('a.action');

      const $container = $expandBlock.parent();

      const $closeBlock = $expandBlock.next('.block--close');
      const $closeButton = $closeBlock.find('a.action');

      // move the close block to the end of the container
      $closeBlock.addClass('block--is-hidden');
      $closeBlock.hide();
      $closeBlock.remove();
      $container.append($closeBlock);

      const $blocks = $container.find('.block');
      // $blocks.last().after($closeBlock);

      let isUnderHidden = false;
      $blocks.each(function(){
        const $block = $(this);
        if(isUnderHidden){
          $block.addClass('block--is-hidden');
          $block.hide();
        }
        if($block.is($expandBlock)){
          isUnderHidden = true;
        }
      });

      $closeButton.on('click', function(e){
        e.preventDefault();
        $expandBlock.slideDown();
        history.replaceState({ closed: true }, null, window.location.pathname);
        $blocks.filter('.block--is-hidden').slideUp(600);
      });

      $expandButton.on('click', function(e){
        e.preventDefault();
        $expandBlock.slideUp();
        history.replaceState({ closed: false }, null, $expandButton.attr('href'));
        $blocks.filter('.block--is-hidden').slideDown(600);
      });

      if(window.location.hash){
        $expandButton.trigger('click');
      }

      console.log($blocks);
    });
  },

  eventListeners: function() {

    // Update on resize
    window.addEventListener('resize', function(e) {
      if (!Sk.ticking) {
        window.requestAnimationFrame(function() {
          Sk.common.navState();
          Sk.ticking = false;
        });

        Sk.ticking = true;
      }
    });

    // Update on scroll
    window.addEventListener('scroll', function(e) {
      if (!Sk.ticking) {
        window.requestAnimationFrame(function() {
          Sk.common.fixedHeader();
          Sk.ticking = false;
        });

        Sk.ticking = true;
      }
    });
  },

  isMobile: function() {
    return window.matchMedia('(max-width: 1023px)').matches;
  },

  prefersReducedMotion: function() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    return mediaQuery.matches;
  }

};

$(function() {
  Sk.common.init();
})

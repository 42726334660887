Sk.countup = {

  init: function() {
    this.bindEvents();
  },

  bindEvents: function() {
    var $countups = $('.sk-countup');

    if (!$countups.length) {
      return;
    }

    $countups.each(function(i, elem) {
      var start = $(elem).attr('data-count-start');
      var finish = $(elem).attr('data-count-finish');
      var target = Sk.countup.getNumberValue(finish);

      if(!(start || finish) || isNaN(target) || Sk.common.prefersReducedMotion()) {
        return;
      }

      var options = Sk.countup.getCounterOptions(start, finish);

      var numAnim = new countUp.CountUp(elem, target, options);
      numAnim.start();

      $(elem).data('countup', numAnim);
    });
  },

  /*
   * Check formatting and return countUp.js options
   */
  getCounterOptions: function(start, finish) {
    var startNumber = this.getNumberValue(start);
    var endString = finish.toString();

    var options = {
      startVal: !isNaN(startNumber) ? startNumber : 0,
      duration: 2,
      enableScrollSpy: true,
      scrollSpyDelay: 0,
      scrollSpyOnce: false,
      useGrouping: false,
    }

    if(endString.indexOf(".") > -1) {
      options.decimalPlaces = endString.split('.')[1].length;
    } else if(endString.indexOf(",") > -1) {
      options.useGrouping = true;
    }

    return options;
  },

  /*
   * Strip non-numeric characters preserving decimal values
   */
  getNumberValue: function(str) {
    var val = str.replace(/[^0-9.]/g, "");
    return Number(val);
  }

};


$(function() {
  Sk.countup.init();
})

Sk.tabs = {

  init: function() {
    $('.tabs__tablist a[role="tab"]').on('click', function(e) {
      e.preventDefault();

      var $tab = $(this);

      if($tab.attr('data-category')) {
        var category = $tab.attr('data-category');
        var $filters = $('[data-js="filters-form"]');

        $pane = $($tab.attr('href'));

        $('.tabs__pane').last().attr('id', category);

        if(category == 'all') {
          category = '';
        }

        if($tab.attr('aria-selected') != 'true' && !$pane.length) {
          $('#search-results').addClass('is-visually-hidden');
          $filters.find('select[name="category"]').val(category);
          $filters.trigger('submit');
        }
      }

      $pane = $($tab.attr('href'));

      if($tab.hasClass('show') || !$pane.length) {
        return;
      }

      $('.tabs__tablist a').attr('aria-selected', 'false');
      $tab.attr('aria-selected', 'true');

      $('.tabs__pane').removeClass('show active');
      $pane.addClass('show active');

      Sk.tabs.updateUrl(this.href);
    });

    var target = window.location.hash;

    if($(target).length) {
      $('.tabs__tablist a[href="'+target+'"]').trigger('click');
    }
  },

  updateUrl: function(url) {
    if(window.history.pushState) {
      window.history.pushState({}, '', url);
    }
  },
};


$(function() {
  Sk.tabs.init();
})
